<template>
  <div class="web-packages">
    <div class="packages-top">
      <div class="pt-left">
        <div class="imt-cats">
          <!-- 搜索 -->
          <el-select v-model="categoryId" placeholder="请选择分类" size="mini" style="width: 200px;margin-left: 25px"
                     clearable
                     class="filter-item">
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.catName"
              :value="item.id">
            </el-option>
          </el-select>
          <el-input v-model="condition" clearable size="mini" placeholder="商品名称" style="width: 188px;margin-left: 12px"
                    class="filter-item"/>
          <el-input v-model="skuNo" clearable size="mini" placeholder="货号" style="width: 188px;margin-left: 12px"
                    class="filter-item"/>
          <el-button class="filter-item" style="margin-left: 20px;height: 30px;margin-top: 9px" size="mini"
                     type="primary" icon="el-icon-search" @click="toSearch">搜索
          </el-button>
        </div>
        <div class="gd">
          <el-table :data="mlList" style="width: 100%" @row-click="toSpec">
            <el-table-column prop="skuName" label="商品名称"/>
            <el-table-column prop="skuNo" label="货号"/>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              @current-change="specPageSearch"
              :pager-count="7"
              layout="total, prev, pager, next"
              :total="specPagination.count"
              :page-count="specPagination.pageCount"
              :current-page="specPagination.pageNumber"
            ></el-pagination>
          </div>
        </div>
      </div>

      <div class="pt-right">
        <div class="buy-packages" style="overflow-y:scroll;">
          <el-table
            :data="datas"
            @row-click="setRow"
            height="100%"
            highlight-current-row
            ref="singleTableRef"
            style="width: 100%">
            <el-table-column align="center" width="50">
              <template slot-scope="scope">
                <li @click.stop="delItem(scope.$index)" class="el-icon-delete-solid"
                    style="color:red;cursor: pointer;"></li>
              </template>
            </el-table-column>
            <el-table-column align="center" label="名称" property="skuName">
            </el-table-column>
            <el-table-column align="center" label="尺码" property="spec"/>
            <el-table-column align="center" label="项目" property="commodityName">
              <template slot-scope="scope">
                <div class="buy-xiaoshou">
                  <span style="width: 100%;">{{scope.row.commodityName}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="fName" v-if="fEmps.length>0" property="empName">
              <template slot-scope="scope">
                <div class="buy-xiaoshou" v-if="scope.row.femp">
                  <span v-if="scope.row.femp.empName" style="width: 100%;line-height: 25px;font-size: 13px">{{scope.row.femp.empName}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="sName" v-if="sEmps.length>0" property="empName">
              <template slot-scope="scope">
                <div class="buy-xiaoshou" v-if="scope.row.semp">
                  <span style="width: 100%;line-height: 25px;font-size: 13px">{{scope.row.semp.empName}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="tName" v-if="tEmps.length>0" property="empName">
              <template slot-scope="scope">
                <div class="buy-xiaoshou" v-if="scope.row.temp">
                  <span style="width: 100%;line-height: 25px;font-size: 13px">{{scope.row.temp.empName}}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="packages-emp" v-if="exist">
          <div class="packages-gw" v-if="fEmps.length>0">
            <span class="gw-title">{{fName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in fEmps" @click="setEmp(item,1)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
          <div class="packages-gw" v-if="sEmps.length>0">
            <span class="gw-title">{{sName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in sEmps" @click="setEmp(item,2)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
          <div class="packages-gw" v-if="tEmps.length>0">
            <span class="gw-title">{{tName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in tEmps" @click="setEmp(item,3)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="packages-bottom">
      <div class="cjs-btn">
        <span></span>
        <span class="total"></span>
        <button class="payment" @click="saveSpec">服务商品入库</button>
      </div>
    </div>

    <!--表单组件-->
    <el-dialog append-to-body :close-on-click-modal="false" :before-close="closeEvent" :visible.sync="visible"
               :title="title" width="600px">
      <div style="margin-top: -10px;">
        <div>
          <el-input placeholder="请输入规格" v-model="specName" class="input-with-select" size="small"
                    style="margin-top: -25px">
            <el-button slot="append" icon="el-icon-search" @click="sousuo"></el-button>
          </el-input>
          <el-table :data="spList" style="width: 100%" height="500" @row-click="setSpec">
            <el-table-column prop="spec" label="规格"/>
            <el-table-column prop="mlStock" label="库存"/>
            <el-table-column prop="retailPrice" label="价格"/>
          </el-table>
        </div>
        <div style="margin-top: 8px;padding-left: 5px">
          <span>商品规格: </span>
          <el-input
            placeholder="未选规格"
            v-model="xzSpec.spec"
            :disabled="true"
            size="small"
            style="width: 300px"
          >
          </el-input>
        </div>
        <div style="margin-top: 10px;">
          <div style="display: flex;flex-direction: row;justify-items: center;line-height: 28px;margin-left: 5px">
            <span style="margin-right: 35px">服务项目</span>
            <el-button size="mini" type="text" @click="toItems">添加</el-button>
          </div>
          <div style="display: flex;flex-direction: column">
            <div
              style="margin-left: 5px;height:35px;display: flex;flex-direction: row;line-height: 35px;border-bottom: #D5D4D4 solid 1px;"
              v-for="item in sySkuItems">
              <span style="width: 85%;padding-left: 12px">{{item.commodityName}}</span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeEvent">取消</el-button>
        <el-button size="small" type="primary" @click="submitSku">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择项目" :visible.sync="syVisible" width="45%">
      <div style="border: 1px solid #ddd;padding: 10px;margin-bottom: 5px" v-if="syCatItems.length>0"
           v-for="item in syCatItems">
        <el-row>
          <el-col :span="5">
            <span class="cat-item">
              {{item.catName}}:
            </span>
          </el-col>
          <el-col :span="19">
            <div>
              <el-checkbox-group v-model="syItemIds">
                <el-checkbox style="margin-top: 3px" v-for="it in item.items" :label="it.id"
                             :key="it.id">
                  {{it.itemName}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer" style="margin-top: 10px">
                <el-button size="small" @click="syVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setSyChecked">确 定</el-button>
            </span>
    </el-dialog>

  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";

  export default {
    name: "servicesku",
    components: {},
    data() {
      return {
        specName: '',
        categoryList: [],
        categoryId: '',
        condition: '',
        skuNo: '',

        mlList: [],
        specItem: {},
        title: '',
        visible: false,

        syItemIds: [],
        syCatItems: [],
        sySkuItems: [],
        syItems: [],
        syItem: '',
        syVisible: false,
        specIndex: -1,
        xzSpec: {},
        spList: [],

        datas: [],
        dataIndex: -1,

        exist: false,
        fName: '',
        sName: '',
        tName: '',
        fEmps: [],
        sEmps: [],
        tEmps: [],

        specPagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

      };
    },

    async created() {
      this.getCategoryList()
      this.getEmpList();
      this.getSkuSpecs()
    },

    methods: {
      async getCategoryList() {
        let data = {
          token: getToken(),
          catType: 1,
          spec: 2,
        }
        let res = await this.$get('/sw/getSwCategoryList', data)
        this.categoryList = res.categoryList
      },

      sousuo() {
        var name = this.specName.replace(/\s+/g, "");
        let specs = []
        this.specItem.skuSpecs.forEach(item => {
          var newStr = item.spec.replace(/\s+/g, "");
          let b = newStr.toUpperCase().match(name.toUpperCase())
          if (b) {
            specs.push(item)
          }
        })
        if (specs.length > 0) {
          this.spList = specs
        } else {
          this.spList = []
        }
      },
      toSearch() {
        this.getSkuSpecs()
      },

      specPageSearch(page) {
        this.getSkuSpecs(page)
      },
      //获取服务商品列表
      async getSkuSpecs(page) {
        let currentPage = page || 1;
        let data = {
          categoryId: this.categoryId,
          condition: this.condition,
          skuNo: this.skuNo,
          isSell: 2,
          token: getToken(),
          currentPage,
        }
        let res = await this.$get("/sw/getSwSkuSpecs", data);
        let list = res.dataSet.list;
        this.mlList = list;
        this.specPagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },


      toSpec(row) {
        this.syItemIds = []
        this.sySkuItems = []
        this.specIndex = -1
        this.specItem = row
        this.spList = row.skuSpecs
        this.title = row.skuName
        this.visible = true
      },

      toItems() {
        this.syVisible = true
        this.getItems()
      },

      setSpec(item) {
        this.xzSpec = {
          id:item.id,
          spec:item.spec,
          skuName:this.specItem.skuName,
        }
      },

      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
        this.fName = res.fName;
        this.sName = res.sName;
        this.tName = res.tName;
        this.exist = res.exist;
      },

      closeEvent() {
        this.visible = false
      },


      async getItems() {
        let res = await this.$get('/sw/getSwCatItems', {token: getToken(), catType: 0, itemType: 0})
        this.syCatItems = res.catItems
        this.syItems = res.items
      },

      submitSku() {
        this.datas = this.datas.concat(this.sySkuItems)
        this.dataIndex = this.datas.length - 1
        this.visible = false
      },

      setSyChecked() {
        this.sySkuItems = []
        if (this.syItems.length > 0 && this.syItemIds.length > 0) {
          this.syItems.forEach(item => {
            this.syItemIds.forEach(id => {
              if (id == item.id) {
                let data = {
                  skuName: this.xzSpec.skuName,
                  specId: this.xzSpec.id,
                  spec: this.xzSpec.spec,
                  commodityId: item.id,
                  commodityName: item.itemName,
                  catId: item.categoryId,
                  quantity: 1,
                  price: item.price,
                  cost: item.cost,
                  first: item.first,
                  second: item.second,
                  third: item.third,
                  femp: {},
                  semp: {},
                  temp: {},
                }
                this.sySkuItems.push(data)
              }
            })
          })
          this.syVisible = false
        } else {
          this.$message.error('请选择项目!')
          return;
        }
      },


      //移除已选择的会员卡
      delItem(index) {
        this.datas.splice(index, 1)
        this.getEmpList()
        this.dataIndex = index - 1
      },

      setRow(item) {
        this.dataIndex = this.datas.indexOf(item);
        this.setEmpStatus(item)
        this.syItems=[]
        this.syItemIds=[]
      },

      //设置员工
      async setEmp(item, type,) {
        if (this.dataIndex == -1) {
          return;
        }
        if (this.datas.length > 0) {
          var check = !item.check
          this.setUnchecked(type);
          if (type == 1) {
            if (check) {
              this.datas[this.dataIndex].femp = {
                empId: item.id,
                empName: item.nickName,
                station: type,
                gradeId: item.gradeId,
                status: 1,
                check: check,
              }
            }
            item.check = check
          }
          if (type == 2) {
            if (check) {
              this.datas[this.dataIndex].semp = {
                empId: item.id,
                empName: item.nickName,
                station: type,
                gradeId: item.gradeId,
                status: 1,
                check: check,
              }
            }

            item.check = check
          }
          if (type == 3) {
            if (check) {
              this.datas[this.dataIndex].temp = {
                empId: item.id,
                empName: item.nickName,
                station: type,
                gradeId: item.gradeId,
                status: 1,
                check: check,
              }
            }
            item.check = check
          }
        }
      },

      async saveSpec() {
        var data = {
          mlStr: JSON.stringify(this.datas),
          token: getToken(),
        }
        let res = await this.$post("/sw/saveSpecOrder", data);
        if (res.code == 200) {
          this.$router.push(`/cashiers/cashier`);
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },

      closePay() {
        this.datas = []
        this.getEmpList()
        this.payVisible = false;
        this.$message.success("结算成功")
      },

      // 将选中的人状态更改为没选中
      setUnchecked(type) {
        if (type == 1) {
          this.changeEmpFalse(this.fEmps);
          this.datas[this.dataIndex].femp = {}
        }
        if (type == 2) {
          this.changeEmpFalse(this.sEmps);
          this.datas[this.dataIndex].semp = {}
        }
        if (type == 3) {
          this.changeEmpFalse(this.tEmps)
          this.datas[this.dataIndex].temp = {}
        }
      },

      // 切换商品更改员工选中状态
      setEmpStatus(item) {
        this.changeEmpFalse(this.fEmps);
        this.changeEmpFalse(this.sEmps);
        this.changeEmpFalse(this.tEmps);
        if (item.femp.station == 1) {
          this.changeEmpTrue(this.fEmps, item.femp)
        }
        if (item.semp.station == 2) {
          this.changeEmpTrue(this.sEmps, item.semp)
        }
        if (item.temp.station == 3) {
          this.changeEmpTrue(this.tEmps, item.temp)
        }
      },

      //更改状态
      changeEmpTrue(emps, emp) {
        emps.forEach(item => {
          if (emp.empId == item.id) {
            item.check = emp.check
          } else {
            item.check = false
          }
        })
      },

      //更改状态
      changeEmpFalse(emps) {
        emps.forEach(item => {
          item.check = false
        })
      },
    },

  };
</script>


<style lang="scss" scoped>
  .web-packages {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    margin-right: 5px;
  }

  .packages-top {
    width: 100%;
    height: 93%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
  }

  .pt-left {
    width: 55%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
  }

  .pt-title {
    display: block;
    height: 50px;
    line-height: 50px;
    border-bottom: #E6E6E6 solid 1px;
    text-align: center;
    font-weight: bold;
  }

  .gd {
    height: 90%;
    overflow: hidden; // 超出部分隐藏
    overflow-y: scroll; // 设置y轴方向的滚动条
    padding: 20px 50px 0px 50px;
  }

  .packages-items {
    width: 95%;
    padding: 0px 2%;
    display: flex;
    flex-flow: row wrap;
  }

  .packages-item {
    /*flex: 1;*/
    box-sizing: border-box;
    width: 178px;
    height: 138px;
    padding: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    /*width: calc((100% - 60px) / 4); // 我这里一行显示4个 所以是/4  一行显示几个就除以几*/
    /*min-width: calc((100% - 60px) / 4);*/
    /*max-width: calc((100% - 60px) / 4);*/

    /*&:nth-child(4n + 4) {*/
    /*  margin-right: 0;*/
    /*}*/
  }

  .packages-item:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
  }

  .packages-img {
    width: 100%;
    height: 98px;
    border-radius: 3px;
  }

  .cpi-name {
    margin-top: 3px;
    line-height: 20px;
    margin-left: 5px;
    font-size: 13px;
  }

  .cpi-price {
    line-height: 20px;
    margin-left: 5px;
    color: red;
    font-size: 15px;
  }

  .pt-right {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }


  .tc-hd {
    height: 47.5px;
    display: flex;
    flex-direction: row;
    border-bottom: #E6E6E6 solid 1px;
    align-items: center;
  }

  .tc-hd span {
    text-align: center;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
  }

  .tc-hd1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
    border-bottom: #E6E6E6 solid 1px;
  }

  .tc-hd1 span {
    text-align: center;
    height: 26px;
    line-height: 26px;
  }

  .tc-item {
    display: flex;
    flex-direction: row;
    border-bottom: #E6E6E6 solid 1px;
    align-items: center;
  }

  .tc-item span {
    text-align: center;
    height: 26px;
    line-height: 26px;
    font-size: 11px;
    color: #ACABAB;
  }

  .buy-packages {
    height: 65%;
    border-right: #F2F2F2 solid 5px;
    font-size: 11px;
  }

  .buy-xiaoshou {
    display: flex;
    flex-direction: column;
  }

  .packages-emp {
    height: 30%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .packages-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 90%;
    margin-left: 5px;
    cursor: pointer;
  }

  .gw-emp .hui {
    width: 90%;
    margin-left: 5px;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .packages-bottom {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 6%;
    background-color: #FFFFFF;
  }

  .cjs-btn {
    width: 328px;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
  }

  .cjs-btn span {
    line-height: 51px;
    width: 50px;
  }

  .cjs-btn .total {
    width: 50%;
    color: red;
  }

  .payment {
    float: right;
    width: 150px;
    height: 51px;
    border: 0;
    background-color: #e82742;
    color: #fff;
    cursor: pointer;
  }

  .bp-nr {
    display: flex;
    flex-direction: column;
  }

  .bp-sp {
    width: 100%;
    border: #C4C4C4 solid 1px;
    margin: 1px 0px;
    border-radius: 3px;
  }

  ::v-deep .el-table__body tr.current-row > td {
    color: #000000;
    background: #EEE9E9 !important;
  }


  .imt-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
    overflow: hidden;
  }

  .imt-cats {
    width: 100%;
    border-bottom: #E6E6E6 solid 1px;
    height: 48px;
    line-height: 48px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-items: center;
  }

  .imt-title {
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin: 0 5px;
  }

  .imt-title1 {
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
  }

  .activeCat {
    color: red;
    border-bottom: red solid 2px;
  }

  .active {
    background-color: #EEE9E9;
  }

  .spitem {
    height: 40px;
    line-height: 40px;
    margin: 3px 5px;
    text-align: center;
    border: #D5D4D4 solid 1px;
    cursor: pointer;
    width: calc((100% - 100px) / 6);
    min-width: calc((100% - 100px) / 6);
    max-width: calc((100% - 100px) / 6);
  }

  .spitem.active {
    color: #e82742;
  }


</style>
